import React from 'react';
import Layout from '../components/layout';
import Title from '../components/title';
import Map from '../components/map';

import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Zoom from 'react-medium-image-zoom';

import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumbs from '../components/breadcrumb';
import * as styles from '../styles/templates/experience.module.scss';

export const query = graphql`
  query ($slug: String!) {
    contentfulHotels(slug: { eq: $slug }) {
      slug
      title
      images {
        gatsbyImageData(
          quality: 100
          height: 300
          width: 300
          layout: FULL_WIDTH
        )
      }
      location {
        lat
        lon
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 700)
            __typename
          }
        }
      }
    }
  }
`;

const Experience = ({ data }) => {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  );
  const Text = ({ children }) => <p className={styles.text}>{children}</p>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target;

        return (
          <div>
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </div>
        );
      },
    },
  };

  const hotel = data.contentfulHotels;

  return (
    <>
      <Layout>
        <Title gatsbyPicture={hotel.images[0]} title={hotel.title} />

        {/* Add SEO Here */}

        <Container className={` ${styles.content}`}>
          <Breadcrumbs
            links={[
              {
                text: 'Hotels',
                link: '/hotels',
              },
              {
                text: hotel.title,
                link: `/hotels/${hotel.slug}`,
              },
            ]}
          />

          <Col
            lg={12}
            className='mx-auto d-flex align-items-center text-center '
          >
            {/* Photo Gallery */}
            {hotel.images && (
              <Container className=''>
                <Row>
                  <Col xs={12} xl={12} xxl={12} className=' mx-auto px-0'>
                    <Row
                      xs={2}
                      sm={3}
                      lg={4}
                      className='g-2 mb-4 justify-content-center'
                    >
                      {hotel.images.map((image) => {
                        return (
                          <Col>
                            <Zoom>
                              <GatsbyImage
                                image={image.gatsbyImageData}
                                alt={image.title}
                                style={{ height: '300px' }}
                              />
                            </Zoom>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
          <Col lg={12} className='mx-auto text-start '>
            <div className={`mt-3 mb-5 pb-5`}>
              {renderRichText(hotel.description, options)}
            </div>
          </Col>
        </Container>
        <Container>
          <Map
            center={{
              lat: hotel.location.lat,
              lng: hotel.location.lon,
            }}
          />
        </Container>
      </Layout>
    </>
  );
};

export default Experience;
